import { Container, Tag, Title, ContResumo, TitleClassif,Tmoda, ButtonContainer } from './styles';
import Modal, {ModalHeader, ModalBody, ModalFooter, useModal} from '../ModalClassificacao/Modal';
import Estrela from '../Estrela/index';
import React, {useState, useReducer } from 'react';
import { AxiosResponse } from 'axios';

import {FaClipboardList} from 'react-icons/fa';
interface IHistoryFinanceCardProps {
  title: string;
  subtitle: string;
  classificacao: string;
  obj: object;
  status: string;
  updateTask:any;
  empresa: string;
  token: string
}

const HistoryFinanceCard: React.FC<IHistoryFinanceCardProps> = ({
  classificacao,
  obj,
  updateTask,
  empresa,
  token
}) => {
  const { isShowing, toggle } = useModal();

  const [observacao, setObservacao] = useState(obj.classificacao?obj.classificacao.obs:'');
  const [estrela, setEstrela] = useState(obj.classificacao?obj.classificacao.classificacao:0);
  const [resolvida, setResolvida] = useState(obj.classificacao?obj.classificacao.executado:undefined);
  const [resolvidat, setResolvidat] = useState();
  const [objId, setObjId] = useState(obj.id);
  const [observacaoValue, setObservacaoValue] = useState('');

  const link = "https://"+ empresa + ".umov.me/CenterWeb/report/schedule/" + obj.id + "/" + obj.accessToken;
  let tagColor: string; 

  if (obj.situation.id === '20'){
      tagColor= '#f44336'
  } else if (obj.situation.id === '30'){
    tagColor= '#ffa500'
  } else if  (obj.situation.id === '40'){
    tagColor= '#008CBA'
  }else if  (obj.situation.id === '50'){
    tagColor= '#4CAF50';
    }
  else if  (obj.situation.id === '70'){
    tagColor= '#bbb9b9';
    }


  return (
    <Container>
      <Tag color={tagColor} />
    
        <div>
          <Title>
            <span>OS: {obj.id} DATA: {obj.insertDateTime}</span>
          </Title>  
        
          <Title>  
            <span>{obj.serviceLocal.description} </span>
          </Title> 
        </div>
        <div>
        <FaClipboardList onClick={toggle}/>    
          {obj.classificacao != undefined?
          <i className='fa fa-check icont'> </i>
          : null}
        </div>
     
        <Modal {...{isShowing, toggle}}>
          <ModalHeader {...{toggle}}>
            
          <span>RESUMO DO ATENDIMENTO</span>
        
          </ModalHeader> 
               <ModalBody> 
                <ContResumo>             
                  <div className="text">
                    <span><b>ID: </b> <a href={link} target="_blank">{obj.id}</a> </span>    
                  </div>
                  <div className="text">
                    <span><b>STATUS: </b> {obj.situation.description}</span>    
                  </div>
                  <div className="text">
                      <span><b>LOCAL: </b> {obj.serviceLocal.description}</span>  
                  </div>
                  <div className="text">
                    <span><b>DATA DE ABERTURA: </b> {obj.insertDateTime}</span>         
                  </div>
                  <div className="text">
                    <span><b>SOLICITANTE: </b> {obj.customFields.responsavel}</span>         
                  </div>
                  <div className="text">
                    <span><b>DESCRIÇÃO DO PROBLEMA: </b> {obj.observation}</span>  
                  </div>
                </ContResumo> 

                {obj.situation.id === '50'? <div>
                  <Estrela 
                  estrela={obj.classificacao?obj.classificacao.classificacao:0}
                  obs={obj.classificacao?obj.classificacao.obs:''}
                  selecao={resolvidat}
                  executado={obj.classificacao?obj.classificacao.executado:undefined}
                  idTask={obj.id}
                  updateTask={updateTask}
                  obj={obj}
                  token={token}
                  ambiente={empresa}
                  /> 
                </div> : null}               
               </ModalBody>
           
             <ModalFooter>
                
          </ModalFooter>  
        
        </Modal> 
         
    </Container>
      
  );
}

export default HistoryFinanceCard;