import { Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer, Container, Title, Tag } from './styles';
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import HistoryFinanceCard from '../../components/HistoryFinanceCard';
import api from "../../Services/api";
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import produtos from '../../Services/produtos';
import swal from 'sweetalert'
import Input from '../../components/Input';
import { useNavigate } from 'react-router';
import { BsFillFileTextFill } from "react-icons/bs";
import moment from 'moment';
import Modal, { ModalHeader, ModalBody, ModalFooter, useModal } from '../../components/ModalQuant/Modal';
import { ImArrowRight, ImArrowLeft } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { FcHighPriority } from "react-icons/fc";
import { FaBeer } from "react-icons/fa";
import { FaClipboardList } from 'react-icons/fa';
import { FaStar } from "react-icons/fa";
import MultipleImageUpload from "../../components/Upload/MultipleImageUpload.js";

interface Locais {
    idLocal: string;
    descricao: string;
    pavimento: string;
    ativo: number;
    ambiente: string;
    grupodelocais: string;
    equiperespons: string;
    ID: number;
}

const Dashboard = (props: any) => {
    const [toggleState, setToggleState] = useState(1);
    const { isShowing, toggle } = useModal();
    const [empresa, setEmpresa] = useState('now');
    const [responsavel, setResponsavel] = useState('');
    const [telefone, setTelefone] = useState('');
    const [local, setLocal] = useState('');
    const [observacao, setObservacao] = useState('');
    const location = useLocation();
    const [mensagem, setMensagem] = useState('');
    const [ambientes, setAmb] = useState(teste);
    const [task, setTask] = useState([]);
    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(99);
    const [ativarButton, setAtivarButton] = useState(undefined);
    const [dataIni, setDataini] = useState('');
    const [datafim, setDatafim] = useState('');
    const [notif, setNotif] = useState('9');
    const [renderNotif, setRenderNotif] = useState();
    const [quantNot, setQuantNot] = useState('0');
    const [token, setToken] = useState('');
    const [tipoSolicitacao, setTipoSolicitacao] = useState('');
    const [dadosUrl, setDadosUrl] = useState([]);
    const [myimage, setMyImage] = React.useState(null);
    const uploadImage = e => {
        setMyImage(URL.createObjectURL(e.target.files[0]));
    };

    api.defaults.headers.common['Authorization'] = sessionStorage.getItem("userToken");

    const mostrarAlert = () => {
        swal({
            title: "testando",
            text: "teste",
            icon: "warning",
            button: "Aceptar",
        });
    }

    function teste() {
        const value = JSON.parse(sessionStorage.getItem("dados"));

        return value.map((data: any) => {
            return <option key={data.OS_ambiente + '*' + data.OS_grupo + '*' + data.OS_token + '*' + data.OS_usu} value={data.OS_ambiente + '*' + data.OS_grupo + '*' + data.OS_token + '*' + data.OS_usu} > {data.OS_descricao} - {data.OS_desc_grupo} </option>
        })
    }

    const [data, setData] = useState([]);
    const [tasks, setTasks] = useState([])
    const [selection, setSelection] = useState({ 20: "", 30: "", 40: "", 50: "", 99: "" });

    async function getLoc() {
        let ret: AxiosResponse;
        let valor: any;
        setLocal('');
        setDatafim('');
        setDataini('');
        const e = document.getElementById('EDTEMP');
        setToken(e?.value.split('*')[2]);
        setEmpresa(e?.value.split('*')[0]);
        sessionStorage.setItem("empresa", e?.value.split('*')[0]);




        if (e?.value.split('*')[0] === 'ECONAIR') {
            swal({
                title: "ATENÇÃO",
                text: `A empresa não está disponivel para abertura de chamado.`,
                icon: "error",
                button: "OK",
            });

            getTask();
        } else {
            getTask();

            try {

                ret = await api.get('user/umovLocais', {
                    params: {
                        //ambiente: e?.value.split('*')[0] ,
                        grupo: e?.value.split('*')[1],
                        user: e?.value.split('*')[3]
                    }
                })




                if (ret.data.status === 200) {


                    valor = ret.data.data.map((prod: Locais) => {
                        return <option key={prod.idlocal} value={prod.idlocal} > {prod.descricao} </option>

                    })


                } else {
                    swal({
                        title: "ATENÇÃO",
                        text: `Não foi possível concluir sua solicitação.`,
                        icon: "error",
                        button: "OK",
                    });
                }
            } catch (e) {
                valor = <option value='0' > {'Nenhum Valor'} </option>

                if (e.response.status === 401) {

                    swal({
                        title: "ATENÇÃO",
                        text: `Token inválido ou expirado`,
                        icon: "error",
                        button: "OK",
                    });
                    sessionStorage.removeItem("userToken");
                    sessionStorage.removeItem("message");
                    sessionStorage.removeItem("username");
                    sessionStorage.removeItem("dados");
                    navigate('/signin')

                } else {
                    swal({
                        title: "ATENÇÃO",
                        text: `Não foi possível concluir sua solicitação.`,
                        icon: "error",
                        button: "OK",
                    });
                }
            }
        }
        //buscar historico de chamados

        setData(valor);
        return valor;
    }

    async function salvarTar() {


        const s = document.getElementById('salvar');
        s.disabled = true

        let ret: AxiosResponse;

        const e = document.getElementById('EDTEMP');

        setEmpresa(e?.value.split('*')[0]);
        sessionStorage.setItem("empresa", e?.value.split('*')[0]);



        if (empresa.length === 0 || empresa === 'now' || empresa === '-- Selecione --') {
            swal({
                title: "ATENÇÃO",
                text: "Selecione a empresa desejada.",
                icon: "error",
                button: "OK",
            });
        }
        else if (responsavel.length === 0) {
            swal({
                title: "ATENÇÃO",
                text: "Informe o Responsável.",
                icon: "error",
                button: "OK",
            });

        }
        else if (telefone.length === 0) {
            swal({
                title: "ATENÇÃO",
                text: "Informe o Telefone.",
                icon: "error",
                button: "OK",
            });

        }
        else if (local.length === 0) {
            swal({
                title: "ATENÇÃO",
                text: "Informe o Local.",
                icon: "error",
                button: "OK",
            });

        }
        else if (observacao.length === 0) {
            swal({
                title: "ATENÇÃO",
                text: "Informe o problema encontrado.",
                icon: "error",
                button: "OK",
            });
        }
        else if (tipoSolicitacao === '') {
            swal({
                title: "ATENÇÃO",
                text: "Informe o tipo de solicitação.",
                icon: "error",
                button: "OK",
            });
        }
        else {
            const formData = new FormData();

            formData.append('img', dadosUrl);
            try {
                ret = await api.post('user/send', formData, {
                    params: {
                        token: e?.value.split('*')[2],
                        ambiente: e?.value.split('*')[0],
                        grupo: e?.value.split('*')[1],
                        requisitante: sessionStorage.getItem("username"),
                        responsavel: responsavel,
                        idLocal: local,
                        fone: telefone,
                        obs: observacao,
                        tipoSolicitacao: tipoSolicitacao,
                    }
                })


                if (ret.data.status === 201) {
                    setResponsavel('');
                    setTelefone('');
                    setLocal('');
                    setObservacao('');
                    getTask();
                    setDadosUrl([]);
                    setTipoSolicitacao("");
                    document.getElementById("file").value = "";
                    
                    swal({

                        title: `Chamado aberto com sucesso`,
                        text: "",
                        icon: "success",
                        button: "OK",
                    });


                } else {
                    swal({
                        title: "ATENÇÃO",
                        text: `Não foi possível concluir o chamado erro: ${ret.data.status}`,
                        icon: "error",
                        button: "OK",
                    });
                }

            } catch (e) {
                if (e.response.status === 401) {
                    swal({
                        title: "ATENÇÃO",
                        text: `Token inválido ou expirado `,
                        icon: "error",
                        button: "OK",
                    });
                    sessionStorage.removeItem("userToken");
                    sessionStorage.removeItem("message");
                    sessionStorage.removeItem("username");
                    sessionStorage.removeItem("dados");

                    navigate('/signin')

                } else {
                    swal({
                        title: "ATENÇÃO",
                        text: `Não foi possível concluir sua solicitação erro: ${e.response.status}`,
                        icon: "error",
                        button: "OK",
                    });
                }


            }
        }
        s.disabled = false
    }


    function reactObj(objeto: any) {
        const chamados = objeto.map((prod: any) => {
            return <HistoryFinanceCard
                tagColor="#4CAF50"
                obj={prod}
                updateTask={updateTask}
                empresa={sessionStorage.getItem("empresa")}
                token={token}
            />
        })
        return chamados;

    }


    async function getTask() {
        let ret: AxiosResponse;
        let chamados: any;



        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        const e = document.getElementById('EDTEMP');
        setEmpresa(e?.value.split('*')[0]);
        sessionStorage.setItem("empresa", e?.value.split('*')[0]);

        setTask(<Loading />);

        try {
            ret = await api.get('user/find', {
                params: {
                    username: sessionStorage.getItem("username"),
                    token: e?.value.split('*')[2],
                    dtini: dataIni,
                    dtfim: datafim
                }
            })
            chamados = reactObj(ret.data.data);
        } catch {

            sessionStorage.setItem("chamados", JSON.stringify(null));
            setTask(<div><br /><h2>Sem chamados recentes.</h2></div>)
        }
        //buscar historico de chamados
        if (chamados !== undefined) {
            setTasks(chamados);
            setTask(chamados);
            sessionStorage.setItem("chamados", JSON.stringify(ret.data.data));
            setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);

        } else {
            setTask(<div><br /><h2>Sem chamados recentes.</h2></div>)
        }

    }

    async function pesquisa() {
        let ret: AxiosResponse;
        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })
        if (empresa.length === 0 || empresa === 'now' || empresa === '-- Selecione --') {
            swal({
                title: "ATENÇÃO",
                text: "Selecione a empresa desejada.",
                icon: "error",
                button: "OK",
            });
        } else {
            if (dataIni !== '' && datafim !== '') {

                if (moment(dataIni) > moment(new Date)) {

                    swal({
                        title: "ATENÇÃO",
                        text: `Data inicial não pode ser maior que data atual.`,
                        icon: "error",
                        button: "OK",
                    });

                } else {

                    if (moment(datafim).diff(moment(dataIni), 'days', true) > 30) {

                        swal({
                            title: "ATENÇÃO",
                            text: `Periodo não pode ser maior que 30 dias.`,
                            icon: "error",
                            button: "OK",
                        });
                    } else {

                        const e = document.getElementById('EDTEMP');
                        setEmpresa(e?.value.split('*')[0]);
                        sessionStorage.setItem("empresa", e?.value.split('*')[0]);

                        setTask(<Loading />);


                        try {
                            ret = await api.get('user/find', {
                                params: {
                                    username: sessionStorage.getItem("username"),
                                    token: e?.value.split('*')[2],
                                    dtini: dataIni,
                                    dtfim: datafim
                                }
                            })
                            chamados = reactObj(ret.data.data);

                        } catch {

                        }

                        //buscar historico de chamados
                        if (chamados !== undefined) {
                            setTasks(chamados);
                            setTask(chamados);
                            setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);
                            sessionStorage.setItem("chamados", JSON.stringify(ret.data.data));
                        } else {
                            setTask(<div><br /><h2>Sem chamados recentes.</h2></div>)
                        }
                    }
                }
            } else {
                swal({
                    title: "ATENÇÃO",
                    text: `Selecione a data inicial e data fim para realizar a busca.`,
                    icon: "error",
                    button: "OK",
                });
            }
        }
    }


    function filtrar(id: number) {

        setFiltro(id);
        sessionStorage.setItem("filtro", id.toString())
        const newSelection = { 20: "", 30: "", 40: "", 50: "", 99: "" }
        setSelection(selection => (
            {
                ...newSelection,
                [id]: "2px dotted #000"
            }
        ))

        let tsk = JSON.parse(sessionStorage.getItem("chamados"));

        if (tsk !== null) {
            if (id !== 99) {

                const newTask = tsk.filter((value) => {
                    return value.situation.id == id ? true : false;

                })
                tsk = reactObj(newTask)
                setTask(tsk);

                setQuantNot(tsk.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);


            } else {
                tsk = reactObj(tsk)
                setTask(tsk);

            }
        } else {
            setTask(<div><br /><h2>Sem chamados recentes.</h2></div>)
        }
    }

    function updateTask(rank: any, executado: any, id: any, obs: any): any {

        const tsk = JSON.parse(sessionStorage.getItem("chamados"));
        const newTask2: any = []



        const newTask = tsk.map((value) => {
            if (value.id === id) {
                newTask2.push({
                    ...value,
                    classificacao: {
                        classificacao: rank,
                        executado: executado,
                        id: id,
                        obs: obs
                    }
                })

                return <HistoryFinanceCard
                    tagColor="#4CAF50"
                    obj={{
                        ...value,
                        classificacao: {
                            classificacao: rank,
                            executado: executado,
                            id: id,
                            obs: obs
                        }
                    }}
                    updateTask={updateTask} />



            } else {
                newTask2.push({ ...value })
                return <HistoryFinanceCard
                    tagColor="#4CAF50"
                    obj={value}
                    updateTask={updateTask} />
            }
        })
        sessionStorage.setItem("chamados", JSON.stringify(newTask2));
        setTask(newTask);


        filtrar(parseInt(sessionStorage.getItem("filtro")));

    }

    function notificacao() {


        if (empresa.length === 0 || empresa === 'now' || empresa === '-- Selecione --') {
            swal({
                title: "ATENÇÃO",
                text: "Selecione a empresa desejada.",
                icon: "error",
                button: "OK",
            });
        } else {
            if (quantNot === '0') {

            } else {
                toggle();
                setToggleState(1);
            }

        }
    }

    async function carregaUrl(obj: any) {
        setDadosUrl(obj)
    }

    async function carregaUrlteste() {
        let ret: AxiosResponse;

        const FormData = require('form-data');


        const formData = new FormData();

        formData.append('img', dadosUrl);

        const e = document.getElementById('EDTEMP');

        try {
            ret = await api.post('user/upload', formData, {
                params: {
                    token: e?.value.split('*')[2],
                    ambiente: e?.value.split('*')[0],
                    grupo: e?.value.split('*')[1],
                    requisitante: sessionStorage.getItem("username"),
                    responsavel: responsavel,
                    idLocal: local,
                    fone: telefone,
                    obs: observacao,
                    tipoSolicitacao: tipoSolicitacao,
                }
            })
            console.log(ret.data)
            console.log(formData)
        } catch (e) {
            console.log(e)
        }
    }

    
    const uploadImg = async (e:any) => { 

        var extensoesPermitidas = /(.jpg|.jpeg|.png|.gif)$/i;


        var arquivoInput = document.getElementById('file');

        var caminhoArquivo = arquivoInput.value;

        var extensoesPermitidas = /(.jpg|.jpeg|.png)$/i;

        if(!extensoesPermitidas.exec(caminhoArquivo)){
            swal({
                title: "ATENÇÃO",
                text: "Por favor envie um arquivo que tenha as extensões permitida.",
                icon: "error",
                button: "OK",
            });
            arquivoInput.value = '';
        }else{
            setDadosUrl(e.target.files[0])
        }
      };
    

    return (

        <DashboardBackground>
            <Header image={empresa} username={sessionStorage.getItem("username")} />
            <BodyContainer>
                <Card noShadow width="90%">
                    <h2 className="h2">ABRIR CHAMADO</h2>
                    <InlineContainer>
                        <LoginInput>
                            <BsFillFileTextFill className='icon' />
                            <select className="input-field" id="EDTEMP" name="EDTEMP" onChange={getLoc} placeholder="Selecione a empresa">
                                <option disabled selected> REGIONAL </option>
                                {ambientes}
                            </select>
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-user icon"></i>
                            <input className="input-field" type="text" id="RESPONSAVEL" placeholder="Responsável" onChange={(e) => setResponsavel(e.target.value)} value={responsavel} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-phone icon"></i>
                            <input className="input-field" type="text" placeholder="Telefone" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                        </LoginInput>
                    </InlineContainer>
                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-solid fa-gear icon"></i>
                            <input className="input-field" list="browsers" placeholder="TIPO DE SOLICITAÇÃO" name="browser" id="browser" onChange={(e) => setTipoSolicitacao(e.target.value)} value={tipoSolicitacao} />
                            <datalist id="browsers">
                                <option value="LIMPEZA" />
                                <option value="MANUTENÇÃO" />
                                <option value="COPA" />
                                <option value="JARDINAGEM" />
                            </datalist>
                        </LoginInput>
                    </InlineContainer>


                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-qrcode icon"></i>
                            <input className="input-field" id="equipment" list="equiplist" type="TEXT" placeholder="UNIDADE" onChange={(e) => setLocal(e.target.value)} value={local} />
                            <datalist id="equiplist">
                                {data}
                            </datalist>
                        </LoginInput>
                    </InlineContainer>
                    <InlineContainer>
                        <LoginInput>
                            <textarea className="input-field" maxLength={2000} placeholder="Observação" onChange={(e) => setObservacao(e.target.value)} value={observacao} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                    <div className="btn-escolha">
                        <input
                            type="file"
                            id="file"
                            onChange={(e) => {
                            uploadImg(e);
                            }}
                        />
                        <br></br>
                    </div>
                    </InlineContainer>

                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    <ButtonContainer>
                        <Button type="button" onClick={salvarTar} id="salvar" >ENVIAR</Button>
                    </ButtonContainer>
                </Card>
            </BodyContainer>

            <BodyContainer>




                <Card noShadow width="90%">
                    <DivTitulo>
                        <a href="#" className="notification" onClick={notificacao}>
                            <IoIosNotifications className='ico-Not' onClick={notificacao} />
                            {quantNot != '0' ?
                                <span className="badge">{quantNot}</span> : null}
                        </a>
                        <h2 className="h2">ÚLTIMOS CHAMADOS</h2>
                    </DivTitulo>
                    <Bot>
                        <button className="button button5" style={{ border: selection[20] }} onClick={() => { filtrar(20) }}>Em analise</button>
                        <button className="button button4" style={{ border: selection[30] }} onClick={() => { filtrar(30) }}>Aguardando</button>
                        <button className="button button3" style={{ border: selection[40] }} onClick={() => { filtrar(40) }}>Em campo</button>
                        <button className="button button2" style={{ border: selection[50] }} onClick={() => { filtrar(50) }}>Encerrada</button>
                        <button className="button button6" style={{ border: selection[99] }} onClick={() => { filtrar(99) }}>Todos</button>
                    </Bot>
                    <Divbut>
                        <div className="container-1">
                            <div id="content1">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idini"

                                    onChange={(e) => setDataini(e.target.value)}
                                    value={dataIni}
                                />

                            </div>
                            <div id="content2">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idfim"
                                    onChange={(e) => setDatafim(e.target.value)}
                                    value={datafim}
                                />

                            </div>
                            <div id="content3">
                                <h1 className="h1-busca" onClick={pesquisa}>Pesquisar</h1>
                            </div>

                        </div>
                    </Divbut>
                    <HistoryChamados>
                        {task}
                    </HistoryChamados>
                </Card>

            </BodyContainer>

            <Modal {...{ isShowing, toggle }}>
                <ModalHeader {...{ toggle }}>

                    <h1></h1>

                </ModalHeader>
                <ModalBody>

                </ModalBody>



                <ModalFooter>
                    <Containertab>



                        <div className="container-tab">
                            <div className="content-tabs">
                                <div
                                    className={
                                        toggleState === 1 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='container-pad'>
                                        <ModalAlinham>

                                            <FcHighPriority className="icone-conf-notif" />
                                            <h1>Atenção! Você possui {quantNot} chamados sem classificação, isso pode impactar na sua abertura de chamados.</h1>
                                            <div className="button-novoAgen">
                                                <button className="btn-agendar" onClick={() => setToggleState(2)}>
                                                    COMO REALIZAR A CLASSIFICAÇÃO
                                                </button>
                                            </div>
                                        </ModalAlinham>

                                    </div>
                                </div>
                                <div
                                    className={
                                        toggleState === 2 ? "content  active-content" : "content"
                                    }
                                >
                                    <h1>1. Verifique se o chamado foi finalizado pelo técnico representado pela cor verde.</h1>
                                    <div className='container-ajuda'>

                                        <ImArrowRight className='icon-seta' />
                                        <Container>
                                            <Tag color={'#4CAF50'} />
                                            <div>
                                                <Title>
                                                    <span>OS: 339722550 DATA: 2022-10-14 14:52:02</span>
                                                </Title>

                                                <Title>
                                                    <span>INTERNAMENTO - WC FEMININO - CHUR TORRE 1</span>
                                                </Title>
                                            </div>
                                            <div>
                                                <FaClipboardList />

                                            </div>
                                        </Container>
                                    </div>
                                    <h1>2. Selecione o ícone localizado a direita do registro para abrir o resumo da OS e realizar a classificação.</h1>
                                    <div className='container-ajuda'>


                                        <Container>
                                            <Tag color={'#4CAF50'} />
                                            <div>
                                                <Title>
                                                    <span>OS: 339722550 DATA: 2022-10-14 14:52:02</span>
                                                </Title>

                                                <Title>
                                                    <span>INTERNAMENTO - WC FEMININO - CHUR TORRE 1</span>
                                                </Title>
                                            </div>
                                            <div>
                                                <FaClipboardList />

                                            </div>
                                        </Container>
                                        <ImArrowLeft className='icon-seta-left' />
                                    </div>
                                    <h1>3. Responda as informações solicitadas e finalize a classificação conforme ilustrado.</h1>
                                    <div className='inf-ilust'>
                                        <div className='cont-ilust'>
                                            <h2 className='solicitacao'>A solicitação foi resolvida?</h2>
                                            <span> <input className="input" type="RADIO" name="OPCAO" checked disabled /> SIM
                                                <input className="input" type="RADIO" name="OPCAO" disabled /> NÃO</span>
                                            <h2 className='solicitacao'> O que você achou do nosso Atendimento?</h2>
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}
                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />

                                        </div>
                                        <div className='cont-ilust'>
                                            <textarea
                                                placeholder="Deixar Seu Comentário" className='textarea-ilust' disabled>
                                            </textarea>


                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </Containertab>








                </ModalFooter>

            </Modal>

        </DashboardBackground>

    )
}

export default Dashboard