import { useState } from "react";
import { FaStar } from "react-icons/fa";
import Button from '../../components/Button';
import { AxiosResponse } from 'axios';
import './styles.css';
import swal from 'sweetalert';
import api from "../../Services/api";
import { useLocation, useNavigate } from 'react-router-dom';


const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
};

function Estrela(props) {
  const [currentValue, setCurrentValue] = useState(props.estrela);
  const [hoverValue, setHoverValue] = useState(undefined);
  //const [selecao, setSelecao] = useState(props.selecao);
  const stars = Array(5).fill(0);
  const [bloquear, setbloquear] = useState('Tcontainer');
  const [observacaoValue, setObservacaoValue] = useState(()=>{  if(props.executado!==undefined){ setbloquear('Tcontainer2')} });
  const [resolvidat, setResolvidat] = useState();
  const [executado, setExecutado] = useState(props.executado);
  //const [classificacao, setClassificacao] = useState(props.classificacao);
  const [idTask, setIdtask] = useState(props.idTask);
  const location = useLocation();
  const navigate = useNavigate();
  
  api.defaults.headers.common['Authorization'] = sessionStorage.getItem("userToken");

  const [opcoes, setOpcoes] = useState(  
    executado!==undefined?
        (executado === 1?
        <span> <input  className="input" type="RADIO" name="OPCAO" checked disabled/> SIM
        <input className="input" type="RADIO" name="OPCAO" disabled/> NÃO</span>:
        <span> <input className="input" type="RADIO" name="OPCAO" disabled/> SIM
        <input className="input" type="RADIO" name="OPCAO"checked disabled/> NÃO</span>)
    :
    <span > <input className="input" type="radio" name="OPCAO" value="1" onChange={(e) => {setResolvidat(e.target.value)}}/> SIM
    <input className="input" type="radio" name="OPCAO" value="0" onChange={(e) => {setResolvidat(e.target.value)}}/> NÃO</span>
    );
    
    const mostrarAlert=()=>{
      swal({
          title:"testando",
          text:"teste",
          icon:"warning",
          button:"Aceptar",
      });
  }

  const handleClick = value => {
    setCurrentValue(value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }
  function handlButtonClink(){
    //setResolvidat(executado);
  
  }

  async function salvarClass(){

    const dados = {
      requisitante: props.obj.customFields.requisitante,
      responsavel: props.obj.customFields.responsavel,
      idLocal: props.obj.serviceLocal.id,
      fone: props.obj.customFields.telefone,
      obs: props.obj.observation,
      idtask: props.idTask,
      instrucao: observacaoValue,
      agent: props.obj.agent.alternativeIdentifier,
      scheduleType: props.obj.scheduleType
    }

    if (resolvidat === undefined) {
      swal({
          title: "ATENÇÃO",
          text:"Selecione SIM ou NÃO para concluir.",
          icon: "error",
          button: "OK",
        });
    }else
    if (resolvidat == 1 && currentValue == 0 ) {
      swal({
          title: "ATENÇÃO",
          text:"Selecione o que você achou do atendimento.",
          icon: "error",
          button: "OK",
        });
    }    
      else{
        let ret = AxiosResponse;
            try{
                ret = await api.post('user/rank',{
                  id: idTask,
                  executado:resolvidat,
                  rank:currentValue,
                  obs:observacaoValue,   
                  dados:dados    
                })   
                
              
                if (ret.data.status === 201){
              
                  setbloquear('Tcontainer2'); 
                  swal({
                      title: `Classificação realizada com sucesso.`,
                      text:"",
                      icon: "success",
                      button: "OK",
                  }); 
                  
                  props.updateTask(currentValue, resolvidat, idTask, observacaoValue)

                  } else {
                      swal({
                          title: "ATENÇÃO",
                          text:`Não foi possível concluir o chamado erro: ${ret.data.mensage}`,
                          icon: "error",
                          button: "OK",
                      
                          
                      }); 
                  }
            }catch(e){
               
              if (e.response.status === 401){
                swal({
                    title: "ATENÇÃO",
                    text:`Token inválido ou expirado `,
                    icon: "error",
                    button: "OK",
                  }); 
                    sessionStorage.removeItem("userToken");
                    sessionStorage.removeItem("message");
                    sessionStorage.removeItem("username");
                    sessionStorage.removeItem("dados");
          
                  navigate('/signin')
                 
               }else {
                swal({
                    title: "ATENÇÃO",
                    text:`Não foi possível concluir a classificação do atendimento erro: ${e.response.status}`,
                    icon: "error",
                    button: "OK",
                  }); 
                } 
                  
            }
      }
  }
  
  return (
   
        <div className={bloquear} >
        <div className="selecao">
      
        <h2 className='solicitacao'>A solicitação foi resolvida?</h2>
          {opcoes}
        </div> 
          <h2 className='solicitacao'> O que você achou do nosso Atendimento?</h2>
          <div style={styles.stars}>
            {stars.map((_, index) => {
              return (
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                  style={{
                    marginRight: 10,
                    cursor: "pointer"
                  }}
                />
              )
            })}
          </div>
          <textarea
            style={styles.textarea} placeholder="Deixar Seu Comentário"
            onChange={(e) => setObservacaoValue(e.target.value)}>
              {props.obs}
            </textarea>

            <Button style={styles.button} type="button" onClick={salvarClass}>ENVIAR</Button>          
        </div>

  );
};

const styles = {


  stars: {
    display: "flex",
    flexDirection: "row",
  },

  Pteste: {
    color: "#a9a9a9",
  },

  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 60,
    width: 300,
  },



  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  }

};


export default Estrela;
 