import styled from 'styled-components';


interface INotiProps{
  valor: '-50px';
}


export const DashboardBackground = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;   
`

export const ModalAlinham = styled.div`
  text-align: center;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;

  .button-novoAgen .btn-agendar{
  width: 70%;
  height: 45px;
  margin-top: 50px;
  border-radius: 8px;
  background-color: #52b788; 
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  border: 0px;  
  cursor: pointer;
 
}

@media(max-width:600px) {
  .button-novoAgen .btn-agendar{
  width: 90%;
  font-size: 16px;
  cursor: pointer;
  
}
}

.button-novoAgen .btn-agendar:hover{
  opacity: 0.8;
}
  h1{
    display: flex;
    font-size: 22px;
    margin-top: 10px;
  }

    .icone-conf-notif{
      font-size: 160px;
    }


 /*------------------------------------------------------- */

 .container-agendas1 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 0.6fr;
  grid-template-areas: "content1 content2";
  margin-bottom: 10px;

}

#content-chamados1 {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  background-color: rgb(255, 255, 255);
 
}


#content-chamados2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding); 
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  background:rgb(255, 255, 255);
  border-left: 1px solid #d4d4d4;
  height: 540px;
}

.cont{
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  height: 5;
  margin-bottom: 10px;
  
}

.cont-busca{
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  height: 5;
  margin-bottom: 20px;   
}


.btn-cont{
  width: 100%;
  font-size: 30px;
  border: 2px solid rgb(175, 173, 173);
  text-align: center;
  padding: 20px; 
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
}

.btn-cont:hover{
  background-color: #fff;
}

.btn-cont-busca{
  width: 100%;
  font-size: 30px;
  text-align: center;
  padding: 5px; 
  color: #fff;
}



.btn-cont2{
  width: 100%;
  height: 60px;
  font-size: 30px;
}

.lab-cont{
 font-size: 25px;
}

.lab-cont-buscar{
  font-size: 20px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #222d32;
  border-radius: 7px;
  cursor: pointer;
  padding: 5px;
 }

 .lab-cont-buscar:hover{
  background-color: #9e9e9e; 
  
 }

.InputBusca-Chamados{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
 
}



.details-Chamados{
  width: 100%;
  font-size: 12px;
 
}


.details-Chamados-f{
  font-weight: bolder;
  margin-bottom: 5px;
}

.input-box-chamado select {
  width: 100%;
  height: 28px;
  padding: 5px;
  display: flex;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
 
  padding-right: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  margin-top: 2px;
  margin-bottom: 2px;
  color: rgb(137, 137, 137);
}

.input-box-chamado input {
  width: 100%;
  height: 28px;
  padding: 5px;
  display: flex;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
 
  padding-right: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  margin-top: 2px;
  margin-bottom: 2px;
  color: rgb(137, 137, 137);
}

.input-box-chamado input:focus {
  border-color: #222d32;
}

.input-box-chamado input:hover{
  border-color: #222d32;
}

.input-box-chamado select:hover{
border-color: #222d32;
}

.input-box-chamado {
  width: 100%;
}

.input-box-chamado span.details {
  display: block;
  font-weight: bold;
 color: #222d32;
 margin-bottom: 7px;
}

.formulario-chamado-title{
width: 100%;
margin-bottom: 15px;
}

.formulario-chamado{
width: 100%;
height: 540px;

}

#content-tab2-chamado h1 {
font-size: 14px;
color: #222d32;
padding: 10px;
}



.input-box-chamado-novo select {
width: 100%;
height: 28px;
padding: 5px;
display: flex;
outline: none;
font-size: 12px;
border-radius: 5px;
padding-right: 10px;
border: 1px solid #ccc;
transition: all 0.3s ease;
margin-top: 2px;
margin-bottom: 10px;
color: rgb(137, 137, 137);
}



.input-box-chamado-novo input {
width: 100%;
height: 28px;
padding: 5px;
display: flex;
outline: none;
font-size: 12px;
border-radius: 5px;
padding-right: 10px;
border: 1px solid #ccc;
transition: all 0.3s ease;
margin-top: 2px;
margin-bottom: 10px;
color: rgb(137, 137, 137);
}

.input-box-chamado-novo textarea{
width: 100%;
height: 80px;
padding: 5px;
display: flex;
outline: none;
font-size: 12px;
border-radius: 5px;
padding-right: 10px;
border: 1px solid #ccc;
transition: all 0.3s ease;
margin-top: 2px;
margin-bottom: 10px;
color: rgb(137, 137, 137);

}

.input-box-chamado-mensag-e{
  display: flex;
  background: #ccc;
  border: 1px solid #ccc;
  margin-top: 20px;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 10px;
 
}



.input-box-chamado-mensag-e textarea{
width: 100%;
height: 35px;
padding: 5px;
outline: none;
font-size: 12px;
border-radius: 5px;
padding-right: 10px;
border: 1px solid #ccc;
transition: all 0.3s ease;
margin-bottom: 10px;
color: rgb(137, 137, 137);
}


.input-box-chamado-novo input:focus {
border-color: #222d32;
}

.input-box-chamado-novo input:hover{
border-color: #222d32;
}

.input-box-chamado-novo {
width: 100%;
}

.input-box-chamado-novo .details-Chamado-novo {
display: block;
font-weight: bold;
color: #222d32;
margin-bottom: 7px;
font-size: 16px;
}


.Bt-button-chamado {
display: flex;
background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
border: 1px solid rgb(175, 173, 173);
height: 45px;
border-radius: 5px;
margin-top: 10px;
padding: 5px;
}

.Bt-button-chamado button {
font-size: 15px;
background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
margin-left: 17px;
width: 100px;
border: 1px solid rgb(175, 173, 173);
cursor: pointer;
border-radius: 5px;
}
.Bt-button-chamado button:hover {
border: 1px solid #455560;
}

.input-box-chamado-novo select:hover {
border: 1px solid #455560;
}

.input-box-chamado-novo textarea:hover {
border: 1px solid #455560;
}


.Bt-button-chamado button{
font-weight: bold;
}

.icon-ek{
color:#0cbd21;
}


.div-title{
display: flex;
background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
margin-bottom: 15px;
margin-top: -20px;
padding: 4px;
margin-left: -10px;
margin-right: -10px;
}

.title-conve{
font-size: 16px;
margin-top: 10px;
margin-left: 5px;
}

#tab-aberto-cham-conversa{
overflow-y: scroll;
overflow-x: hidden;
}

#tab-aberto-cham-conversa::-webkit-scrollbar {
width: 10px;     
}

#tab-aberto-cham-conversa::-webkit-scrollbar-track {
background: #e0e9f0;  
margin-top: 10px; 
margin-bottom: 10px;  
border-radius: 5px; 

}

#tab-aberto-cham-conversa::-webkit-scrollbar-thumb {
background-color: rgb(199, 199, 199);    
border-radius: 20px;     

}


/*------*/
.tab-aberto-cham{
overflow-y: auto;
overflow-x: hidden;
height: 450px;

}

.tab-aberto-cham::-webkit-scrollbar {
width: 10px;     
}

.tab-aberto-cham::-webkit-scrollbar-track {
background: #e0e9f0;  
margin-top: 10px; 
margin-bottom: 10px;  
border-radius: 5px; 

}

.tab-aberto-cham::-webkit-scrollbar-thumb {
background-color: #d4d4d4;    
border-radius: 20px;     

}

.nav-lu{
list-style-type: none;
margin: 0;
padding: 0;
overflow: hidden;
background-color: #222d32;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
}

.formulario-chamado .nav-li {
float: left;
}

.formulario-chamado .nav-li  a {
display: block;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}

.formulario-chamado .nav-li a:hover {
background-color: #111;
}

.InputBusca-chamado{
display: flex;
width: 100%;
margin-bottom: 20px;
}
.InputBusca-chamado{
display: none;
}

.div-novo-cham{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.title-modal{
margin-left: 10px;
}

@media only screen and (max-width: 768px) {


.container-agendas1 {
grid-template-columns: 1fr;
grid-template-areas:
  "content1"
  "content2";

}

.InputBusca-chamado{
display: inline;
display: flex;
}



#content-chamados2{
display: none;
}

}

/*----------------------------------------------*/

.bloc-tabs-chamado {
display: flex;
background:#222d32;

}



.bloc-tabs-chamado #content-tab2-chamado h1{
color: #ffffff;

}

.tabs-chamado {
background-color: #222d32;
padding: 2px;
text-align: center;
cursor: pointer;
border-bottom: 1px solid rgba(0, 0, 0, 0.274);
box-sizing: content-box;
position: relative;
outline: none;
}


.tabs-chamado:not(:last-child) {
border-right: 1px solid rgba(0, 0, 0, 0.274);

}

.active-tabs-chamado {
background: white;
border-bottom: 1px solid transparent; 
border-top: 1px solid #222d32;
}


.active-tabs-chamado #content-tab2-chamado h1{
color: #222d32; 
margin-left: 15px;
}

.active-tabs::before {
content: "";
display: block;
position: absolute;
top: -2px;
left: 50%;
transform: translateX(-50%);
width: calc(100% + 2px);
height: 2px;
background: #222d32;

}

.content-tabs {
flex-grow: 1;

}

.content-chamado  {
background: white;
padding-top: 20px;
padding-bottom: 10px;
padding-left: 10px;
padding-right: 5px;
width: 100%;

display: none;  
}

.active-content {
display: block;

}

.containertab-1 {
display: grid;
width: 100%;
grid-template-columns: 0.1fr 1fr 0.1fr;
grid-template-areas: "content1 content2 content3 ";
grid-gap: 0.1rem;
}

#content-tab1 {
grid-area: content1;
border-radius: var(--main-radius);
padding-top: var(--main-padding);
flex-wrap: wrap;
justify-content: space-between;
font-size: 14px;
color: #455560;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-bottom: 7px;
}

#content-tab2 {
grid-area: content2;
border-radius: var(--main-radius);
padding-top: var(--main-padding);
flex-wrap: wrap;
justify-content: space-between;
font-size: 14px;
color: #455560;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding-left: 5px;
padding-right: 5px;

}

#content-tab3 {
grid-area: content3;
border-radius: var(--main-radius);
padding-top: var(--main-padding);
font-size: 14px;
color: #455560;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}


.icone-Modal {
font-size: 26px;
color: #455560;
margin-top: 5px;
}


#content-tab2 h1 {
font-size: 14px;
color: #222d32;
}

#myDIV {
overflow-y: auto;
overflow-x: hidden;
height: 390px;
width: 100%;
display: block;
float: none;
padding-left: 10px;
padding-right: 10px;
}

#content {
float: none;
clear:both
}

#myDIV::-webkit-scrollbar {
width: 10px;     
}

#myDIV::-webkit-scrollbar-track {
background: #e0e9f0;  
margin-top: 10px; 
margin-bottom: 10px;  
border-radius: 5px; 


}

#myDIV::-webkit-scrollbar-thumb {
background-color: rgb(199, 199, 199);    
border-radius: 20px;     

}

.btn-status{
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
 
}

.btn-sel-dir{
  width: 15%;
  height: 26px;
  background-color: #3CB371;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.514);
}

.btn-sel-esc{
  width: 15%;
  height: 26px;
  background-color: #676666;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.514);
}

.titulo-status{
  font-size: 16px;
  text-align: center;
  color: #FFF;
  margin-top: 4px;

}
.btn-sel-esc:hover{
opacity: 0.8;
}

.btn-sel-dir:hover{
  opacity: 0.8;
  }

.btn-baixo{
  float: right;
  margin-right: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #111;
  background-color: #d6d3d3;
  padding: 2px;
  border-radius: 10px;
}

.btn-enviar{
  font-size: 22px;
margin-top: 5px;
margin-left: 10px;
margin-right: 15px;
cursor: pointer;
}

.btn-enviar:hover{
 opacity: 0.6;
}


.btn-voltar{
  font-size: 35px;
  padding-left: 5px;
  cursor: pointer;
}

.btn-voltar:hover{
opacity: 0.8;
}

.div-btn-voltar{
width: 100%;
height: 30px;
color: #111;
}

.btn-baixo:hover{
opacity: 0.8;
background-color: #a8a8a8;
}

.btn-add{
  width: 160px;
  margin-top: 10px;
  margin-left: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: #222d32;
  color: #fff;
  font-weight: bold;
}

.btn-add:hover{
  opacity: 0.8;
}



@media (max-width: 500px) {
  .btn-sel-dir{
    width: 30%;
  }
  
  .btn-sel-esc{
    width: 30%;
  }

  .btn-status{
    margin-top: 20px;
  }
  
  .tab-aberto-cham{
    max-height: 500px;
    }

 

}
    
`

export const ModalIco = styled.div`
    flex-wrap: wrap;
    justify-content: center;   
    text-align: center;
`

export const ModalQuant = styled.span`
  color: red;
  font-size:  20px;
  font-weight: bold;
`


export const ModalLab = styled.div`
    font-size:  30px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    color: red;
`


export const ModalLab2 = styled.div`
    font-size:  20px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
`

export const HistoryChamados = styled.div`
    overflow-y: auto;
    min-height:200px;
    overflow-x: hidden;
    width: 100%;
`
export const BodyContainer = styled.div`
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
    background-color: white;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    width: 550px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 15px;
    display: flex;

    .h2{
    font-size: 22px;
    margin-top: -25px;
    margin-bottom: 20px;
    background-color: #52b788;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 8px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    }
    
    @media(max-width:600px) {
  .h2{
    font-size: 15px;
  }

 
}

    
`
export const InlineTitle = styled.div`
    display: flex;
`

export const InlineContainer = styled.div`
    width: 100%;
    margin-top: 13px;
    display: flex;
    align-items: center;
    flex-direction: column;

    
.btn-escolha{
    margin-top: 35px;
}

`


export const LoginInput = styled.div`  
    display: flex;
    width: 100%;
    height: 35px;
    
    .icon {
        padding: 10px;
        background: #52b788;
        color: white;
        min-width: 2px;
        text-align: center; 
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }

      .input-field {    
        width: 100%;
        padding: 5px;
        outline: none;
        font-size: 14px;
        text-transform:uppercase;
        border: 1px solid #C0C0C0; 
     
        font-weight: bold;
      }
      
      input
        {
      
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        } 

        select {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

.input-field:focus {
        border: 1px solid #52b788;   
      }   

      .input-field:hover{
        border: 1px solid #52b788;   
      }

      textarea{
        height:60px;
        border-radius: 6px;

      }
  
`
export const Divbut = styled.div`

width: 100%;
margin-top: 10px
;
.container-1 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3";
  margin-bottom: 10px;
  grid-gap: 0.9rem;
}

.input-data{
height: 27px;
width: 100%;
border: 1px solid;   
border-radius: 5px;
border: 1px solid #C0C0C0; 
padding-left: 5px
}

.input-data:hover{
  border: 1px solid #52b788;   
}

.input-data:focus {
        border: 1px solid #52b788;   
      } 

.h1-busca{
  margin-top: 3px;

}

#content3{
  padding: 2px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  text-align: center;
  color: #000000;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

#content4{
  padding: 2px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  text-align: center;
  color: #000000;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}



#content3:hover {
  opacity: 0.7;
  border: 1px solid black;
  }

  
#content4:hover {
  opacity: 0.7;
  border: 1px solid black;
  }

@media(max-width:600px) {
 
  .container-1{
      grid-template-columns: 1fr;
      grid-template-areas: 
      "content1" 
      "content2" 
      "content3";
    }

  
    #content3{
      width: 100%;
    }

    .input-data{
      width: 100%;
      margin-bottom: 10px;
    }

  } 

 
`

export const DivTitulo = styled.div<INotiProps>`
margin: 0px 0px 20px 0px;
display: flex;

  .notification {
  color: black;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  margin-right: 20px;
  margin-top: -10px;

}

.ico-Not{
  color: black;
  font-size: 28px;
}

.notification .badge {
          width: auto;
          height: 18px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
          padding-left: 5px;
          padding-right: 5px;
}
`
export const ButtonContainer = styled.div`
   width: 90%;
   margin-top: -1px;
   display: flex;
   align-items: center;
   flex-direction: column;
   
   #salvar{
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
   }

   p{
       font-size: 0.75rem;
       font-weight: 400;
       color: ${({theme})=> theme.colors.secondary};
 
      a {
       font-size: 1rem;
       font-weight: 700;
       
      }

   }

`

export const DivFiltro = styled.div`
`

export const Bot = styled.div`



.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    display: flex;
    padding: 5px 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 4px 2px;
    margin-top: -20px;
    cursor: pointer;
    font-weight: bold;
  }

  .button2 {
  background-color: #4CAF50;
  border-radius: 6px;
  } 
  .button3 {
    background-color: #008CBA;
    border-radius: 6px;
  } 

  .button4 {
    background-color: #ffa500;
    border-radius: 6px;

  }

  .button5 {
    background-color: #f44336;
    border-radius: 6px;
  } 

  .button6 {
    background-color: #999;
    border-radius: 6px;
  }
 `
 

 export const InputContainer = styled.div`
   margin-top: 0px;
   width: 90%;
   flex: 1;
`



 /*----------------------------TAB INI------------------------- */
 export const Containertab = styled.div`

 width: 100%;
 height: 100%;

.estrela-cor{
 color: orange
}

.textarea-ilust{
    border: "1px solid #a9a9a9";
    border-radius: 5;
    padding: 10;
    margin: "20px 0";
    width: 300px;
    height: 60px;
  }

  .cont-ilust{
    text-align: center;
    margin-top: 10px;
  }

 .icon-seta{
  font-size: 30px;
  margin-top: 22px;
  color: red;
  margin-right: -8px;
 }

 .icon-seta-left{
  font-size: 30px;
  margin-top: 22px;
  color: red;
  margin-left: -8px;
 }

 .inf-ilust{
  border: 2px solid red;
  margin-top: 5px;
margin-left: 80px;
margin-right: 80px;
padding: 5px;
 }

 @media(max-width:600px) {
  .icon-seta{
  margin-top: 43px;
 }
 .icon-seta-left{
  margin-top: 43px;
 }

 .inf-ilust{
margin-left: 0px;
margin-right: 0px;
border: 0px;
 }

}
 .container-tab {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.container-ajuda{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
 

.bloc-tabs {
  display: flex;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);

}

.tabs {
  text-align: center;
  width: 50%;
  height: auto;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;

}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
  
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;    
}
.active-tabs h1{
color:#139c31
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  background: #222d32;
  
}


.content-tabs {
  flex-grow: 1;
  
}

.content {
  background: white;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
}

.active-content {
  display: block;
  
}

.containertab-1 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  grid-template-areas: "content1 content2 content3 ";
  grid-gap: 0.1rem;
}

#content-tab1 {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

#content-tab2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

}

#content-tab3 {
  grid-area: content3;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  font-size: 14px;
  color: #455560;
  max-height: 560px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.icone-Modal {
  font-size: 20px;
  color: #455560;
  margin-top: 5px;
}


#content-tab2 h1 {
  font-size: 14px;
  color: #222d32;
}
`
/*----------------------------TAB FIM------------------------- */

interface ITagProps {
  color: string;
}

interface IButtonProps {
  color: string;
}

export const IButton = styled.div<IButtonProps>`
    background-color: ${props => props.color};
    width: 60px;
    height: 10px;
`;

export const Container = styled.li`
background-color: ${props => props.theme.colors.tertiary};
width: 100%;
justify-content: space-between;
list-style: none;
border-radius:  5px;
margin: 10px ;
padding: 10px 20px 10px;
display: flex;

align-items: center;
cursor: pointer;
transition: all .3s;
position: relative;
> div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}
.icont{
  color: #4CAF50;
  margin-top: 3px;
}
`;

export const Tag = styled.div<ITagProps>`
    width: 0px;
    height: 60%;
    background-color: ${props => props.color};
    position: absolute;
    left: 0;
`;

export const Title = styled.div`
 text-align: left;
 font-weight: bold;
 span{
  font-size: 13px; 
 }
`
export const ContResumo = styled.div`
margin: 0 0 0 10px;

  .text{
    margin-top: 1px
  }
  span{
    text-transform: uppercase;
    font-size: 13px; 
  }
  b{
    font-size: 13px; 
  }
`
export const TitleClassif = styled.div`
  margin-top: 25px;
  text-align: center;

  h2{
    font-size: 13px; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
 
  input{
    margin: 5px;
    font-weight: bold;
  }
`

export const Tmoda = styled.div`
margin: 10px;
  .text{
    margin-top: 7px
  }
`
